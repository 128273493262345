import React, { useState } from 'react'

import blob from '../../images/blob-corner.png'

import './index.css'

const Blob = () => {

  // local state

  const [loaded, setLoaded] = useState(false)
  
  return (
    <div id="blob">
      <img 
        src={blob}
        onLoad={() => setLoaded(true)}
        className={ !loaded ? 'invisible' : 'animate' }
      />
    </div>
  )
}
export default Blob