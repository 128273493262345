import React, { useState } from 'react'
import { useGlobal } from 'reactn'

import NumberFormat from 'react-number-format'
import OutsideAlerter from './outside-alerter'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

import MakeMoney from '../../../../make-money'

import './index.css'

const EditStreams = () => {

  // global state

  const [streams, setStreams] = useGlobal('streams')
  const [maxStreams, setMaxStreams] = useGlobal('max-streams')

  // local state

  const [editing, setEditing] = useState(false)

  // handlers

  const allowed = value => {
    return value >= 0 && value <= 50000000000
  }

  const handleChange = value => {
    const zero = value === '' || value === '0'
    if(zero) {
      setStreams(0)
      setMaxStreams(2000000000)
      return
    }
    else {
      setStreams(value)
      setMaxStreams(value * 2)
    }
  }

  const handleEditing = () => {
    setEditing(true)
    trackCustomEvent({
      category: "button",
      action: "click",
      label: "edit-streams",
    })
  }

  const handleDoneEditing = () => setEditing(false)

  const handleKeyDown = e => { if(e.key === 'Enter') handleDoneEditing() }

  // render

  return (
    <div id="edit-streams">
      { 
        editing
          ?
            <OutsideAlerter handler={handleDoneEditing}>
              <NumberFormat onKeyDown={handleKeyDown} value={streams} decimalScale={0} thousandSeparator={true} onValueChange={values => handleChange(values.value)} isAllowed={values => allowed(values.value)} className="debt-input" allowLeadingZeros={false} onMouseUp={e => e.target.select()} />
            </OutsideAlerter>
          :
            <div id="fix-height">
              <MakeMoney buttonOnly />
              <button id="edit-streams-button"className="button label-1" onClick={handleEditing}>
                Edit Streams
              </button>
            </div>
      }
    </div>
  )
}

export default EditStreams