import { maxIncome, minIncome } from '../../constants'

export const computeShare = (state, royalty, party) => {
  const dealType = state.dealType.value
  const distroFee = state["distribution-fee"]
  const recoupableAmount = state["recoupable-amount"]
  const { income } =  state 
  let shareAmount
  if(dealType === 'royalty') {
    if(party === 'label') shareAmount = (royalty * income) / 100
    else shareAmount = (royalty * income) / 100
  }
  if(dealType === 'net-profit-split' || dealType === 'distribution-deal') {
    const distroFeeAmount = (distroFee / 100) * income
    const profitToSplit = (income - distroFeeAmount) - recoupableAmount
    shareAmount = profitToSplit * (royalty / 100) // distribution fee comes off the top 
    if(shareAmount < 0) shareAmount = 0
  }
  return shareAmount
}

export const computeProfit = (state, royalty, party) => {
  let profit
  const dealType = state.dealType.value
  const { income } =  state
  const distroFee = state["distribution-fee"]
  const recoupableAmount = state["recoupable-amount"]
  const royaltyAsPercentage = (royalty / 100)
  if(dealType === 'royalty') {
    if(party === 'label') {      
      const artistUnrecouped = computeUnrecouped(state, state["artist-royalty"])
      const labelRoyalty = income * royaltyAsPercentage
      profit = (labelRoyalty - recoupableAmount) + (recoupableAmount - artistUnrecouped)
      // console.log('ARTIST UNRECOUPED: ', artistUnrecouped)
      // console.log('LABEL ROYALTY: ', labelRoyalty)
      // console.log('ROYALTY - RECOUPABLE AMOUNT', (labelRoyalty - recoupableAmount))
      // console.log('RECOUPABLE AMOUNT - ARTIST UNRECOUPED)', (recoupableAmount - artistUnrecouped))
      // console.log('PROFIT: ', profit)
    }
    else profit = (income * royaltyAsPercentage) - recoupableAmount
  }
  if(dealType === 'net-profit-split' || dealType === 'distribution-deal') {
    const distroFeeAmount = income * (distroFee / 100)
    const profitToSplit = (income - distroFeeAmount) - recoupableAmount
    profit = (profitToSplit * royaltyAsPercentage)
    console.log('distrofeeamount: ', distroFeeAmount)
    console.log('profittosplit: ', profitToSplit)
    console.log('profit: ', profit)
  }
  if(profit <= 0) return 0
  else return profit
}



export const computeLabelBalance = state => {
  const income = state["income"]
  const artistUnrecouped = computeUnrecouped(state, state["artist-royalty"])
  const labelRoyalty = income * (state["label-royalty"] / 100)
  const recoupableAmount = state["recoupable-amount"]
  const dealType = state.dealType.value
  let balance
  if(dealType === 'royalty') balance = (labelRoyalty - recoupableAmount) + (recoupableAmount - artistUnrecouped)
  else {
    const unrecovered = (Math.min((income - computeDistroFee(state) - recoupableAmount), 0))
    balance = computeShare(state, state["label-royalty"], 'label') + unrecovered + computeDistroFee(state)
    console.log('unrecovered: ', unrecovered)
    console.log('label royalty: ', computeShare(state, state["label-royalty"], 'label'))
    console.log('distro fee: ', computeDistroFee(state))
    console.log('HACER UN BALANCE: ', balance)
  }
  // console.log('ARTIST UNRECOUPED: ', artistUnrecouped)
  // console.log('LABEL ROYALTY: ', labelRoyalty)
  // console.log('ROYALTY - RECOUPABLE AMOUNT', (labelRoyalty - recoupableAmount))
  // console.log('RECOUPABLE AMOUNT - ARTIST UNRECOUPED)', (recoupableAmount - artistUnrecouped))
  // console.log('PROFIT: ', balance)
  return balance
}

export const computeUnrecouped = (state, royalty) => {
  let unRecouped
  const dealType = state.dealType.value
  const { income } =  state
  const recoupableAmount = state["recoupable-amount"]
  const distroFee = state["distribution-fee"]
  if(dealType === 'royalty') unRecouped = recoupableAmount - ((royalty * income) / 100)
  if(dealType === 'net-profit-split' || dealType === 'distribution-deal') {
    const royaltyAsPercentage = (royalty / 100)
    const distroFeeAmount = income * (distroFee / 100)
    unRecouped = recoupableAmount - (income - distroFeeAmount)
  } 
  if(unRecouped <= 0) return 0
  else return unRecouped
}

export const computeDistroFee = state => {
  const distroFee = state["distribution-fee"]
  const { income } =  state 
  return income * (distroFee / 100)
}

// export const computeMaxShare = (state, royalty) => {
//   const royaltyAsPercentage = (royalty / 100)
//   const dealType = state.dealType.value
//   const distroFee = state["distribution-fee"]
//   const recoupableAmount = state["recoupable-amount"]
//   if(dealType === 'royalty') return maxIncome * royaltyAsPercentage  
//   if(dealType === 'net-profit-split' || dealType === 'distribution-deal') return (maxIncome - distroFee - recoupableAmount) * royaltyAsPercentage
// }

export const makeMoneyPoint = state => {
  const dealType = state.dealType.value
  const artistRoyalty = state["artist-royalty"]
  const recoupableAmount = state["recoupable-amount"]
  const rate = state.rate
  const royaltyAsPercentage = (artistRoyalty / 100)
  const distroFee = state["distribution-fee"]
  let necessaryIncome = recoupableAmount / royaltyAsPercentage
  if(dealType === 'net-profit-split' || dealType === 'distribution-deal') {
    const distributionFeeAsPercentage = state["distribution-fee"] / 100
    necessaryIncome = recoupableAmount / (1 - distributionFeeAsPercentage)
  }
  // const necessaryStreamsInMillions = necessaryIncome / 5000
  // const necessaryStreams = necessaryStreamsInMillions * 1000000
  const necessaryStreams = necessaryIncome / rate
  const numberOfStreamsEqualToOneDollar = 1 / rate
  if(dealType === 'royalty') return necessaryStreams + (numberOfStreamsEqualToOneDollar / royaltyAsPercentage)
  if(dealType === 'net-profit-split' || dealType === 'distribution-deal') {
    return necessaryStreams + ((numberOfStreamsEqualToOneDollar / royaltyAsPercentage) / (1 - (distroFee / 100)))
  }
}