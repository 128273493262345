import React from "react"
import csLogo from '../images/cs-logo-white.svg'

import { OutboundLink } from 'gatsby-plugin-google-analytics'

import './header.css'

const Header = () => {
  
  return(
    <header className="row align-center">
      <OutboundLink href="https://createos.app/" target="_new">
        <img src={csLogo} id="header-logo" />
      </OutboundLink>
    </header>
  )
}

export default Header
