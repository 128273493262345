import React from 'react'
import Info from '../info'
import RateSlider from './rate-slider'

import './index.css'

const RevenueHeading = () => (
  <div id="revenue-heading">
    <div className="flex-row" id="streams-heading">
      <div>
        <div className="flex-row">
          <label className="label-1" style={{ marginRight: 5 }}>Streaming</label>
        </div>
        <p>The Record Deal Simulator focuses on streaming revenue because streaming is the dominant method of present day music consumption.</p>
      </div>
    </div>
    <RateSlider />
  </div>
)

export default RevenueHeading