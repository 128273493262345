import React from 'react'
import { useGlobal } from 'reactn'
import { makeMoneyPoint } from '../deal-simulator/components/revenue/math'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

import './index.css'

const MakeMoney = ({ buttonOnly }) => {

  // global state

  const [global] = useGlobal()
  const [streams, setStreams] = useGlobal('streams')
  const [maxStreams, setMaxStreams] = useGlobal('max-streams')

  const makeMoney = () => {
    trackCustomEvent({
      category: "button",
      action: "click",
      label: "make-money"
    })
    const makingIt = makeMoneyPoint(global)
    setStreams(makingIt)
    setMaxStreams(makingIt * 2)
  }

  return <button onClick={makeMoney} id="make-money-inside" className="margin-hz button label-1">MAKE MONEY</button>

  // return(
  //   <div id="make-money" className="column">
  //     <div id="mm-content">
  //       <p>See the number of streams needed to begin making money</p>
  //       <button onClick={makeMoney} className="margin-hz button">Make Money</button>
  //     </div>
  //   </div>
  // )
}

export default MakeMoney