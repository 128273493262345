import React from 'react'
import DealTerms from './components/deal-terms'
import Revenue from './components/revenue'

import './index.css'

const DealSimulator = () => {

  // render

  return (
    <div id="simulator" className="flex-row bg-1 white-text">
      <DealTerms />
      <Revenue />
    </div>
  )
}

export default DealSimulator