import React, { useState, useEffect } from 'react'
import { useGlobal } from 'reactn'

import Select from 'react-select'
import Info from '../../info'

import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

const royaltyDealDescription = "The artist must recoup all costs/advances in order to break even."
const netProfitSplitDealDescription = "All costs are repaid before either party receives a share."
const distributionDealDescription = "Artists retain ownership of their music and receive distribution services."
const Type = () => {

  // global state

  const [dealType, setDealType] = useGlobal('dealType')
  const [distributionFee, setDistributionFee] = useGlobal('distribution-fee')
  const [artistSplit, setArtistSplit] = useGlobal('artist-royalty')
  const [labelSplit, setLabelSplit] = useGlobal('label-royalty')

  // local state

  const [dealDescription, setDealDescription] = useState(royaltyDealDescription)

  // effects 

  useEffect(() => {
    if(dealType.value === 'royalty') setDealDescription(royaltyDealDescription)
    if(dealType.value === 'net-profit-split') setDealDescription(netProfitSplitDealDescription)
    if(dealType.value === 'distribution-deal') setDealDescription(distributionDealDescription)
  }, [dealType])
  
  // deal type config

  const dealTypes = [
    { value: 'royalty', label: 'Royalty Deal' },
    { value: 'net-profit-split', label: 'Net Profit Deal' },
    { value: 'distribution-deal', label: 'Distribution Deal'}
  ]

  // handlers

  const handleChange = selectedDealType => {
    setDealType(selectedDealType)
    if(selectedDealType.value === 'royalty') {
      setDistributionFee(0)
      setArtistSplit(20)
      setLabelSplit(80)
    }
    if(selectedDealType.value === 'net-profit-split') {
      setDistributionFee(15)
      setArtistSplit(50)
      setLabelSplit(50)
    }
    if(selectedDealType.value === 'distribution-deal') {
      setDistributionFee(0)
      setArtistSplit(70)
      setLabelSplit(30)
    }
    trackCustomEvent({
      category: "input",
      action: "select",
      label: selectedDealType.value,
    })
  }

  // render

  return (
    <div className="input-element" id="type">
      <div className="margin-bottom flex-row align-center space-between">
        <span className="label-1">Deal Type</span>
        <Info title={dealType.value} />
      </div>
      <Select isSearchable={false} options={dealTypes} value={dealType} onChange={v => handleChange(v)} className="deal-type" classNamePrefix="deal-type" />
      <p id="deal-description">
        {dealDescription}
      </p>
    </div>
  )
}

export default Type