import React from 'react'

import './index.css'

const About = () => {
  return(
    <div id="about" className="width-45">
      <h4>About Create/OS</h4>
      <p>Create/OS is the Operating System for the Music Business. Join us as we build for the New Music Industry.</p>
    </div>
  )
}

export default About