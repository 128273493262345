import React, { useState } from 'react'

import './index.css'

const PageHeading = () => {

  // local state

  const [loaded, setLoaded] = useState(false)

  // just wait a bit for font to load

  setTimeout(() => {
    setLoaded(true)
  }, 400)

  // main render

  return(
    <div id="page-heading" className="column flex">
      <h1 className={`${!loaded ? 'invisible' : 'slide-in'} white-text title no-margin`}>
        Record Deal Simulator
      </h1>
    </div>
  )
}

export default PageHeading