import React from 'react'

import './index.css'

const GradientInput = (props) => {
  const { placeholder, value, onChange, className, id, disabled, style, key } = props
  return(
    <div id="input-gradient-wrapper" className="full-width margin-bottom">
      <input
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={className}
        id={id}
        disabled={disabled}
        style={style}
        autoComplete={false}
        key={`apply-input-${key}`}
      />   
    </div>
  )  
}

export default GradientInput