import React, { useEffect, useState } from 'react'
import { useGlobal } from 'reactn'

import Slider from '@material-ui/core/Slider'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'

import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

const Split = () => {

  // global state

  const [artistSplit, setArtistSplit] = useGlobal('artist-royalty')
  const [labelSplit, setLabelSplit] = useGlobal('label-royalty')
  const [dealType] = useGlobal('dealType')

  // local state

  const [open, setOpen] = useState(false)
  const [explanation, setExplanation] = useState('')

  // listen for changes globally

  useEffect(() => {
    setArtistSplit(artistSplit)
    setLabelSplit(100 - artistSplit)
  }, [artistSplit])

  // accessibility
  
  function valuetext(value) {
    return `${value}°C`;
  }

  // handlers

  const handleChange = (e, v) => {
    let value = v
    if(value >= 95) {
      value = 95
      if(artistSplit !== 95) explainLimit('max')
    }
    if(value <= 5) {
      value = 5
      if(artistSplit !== 5) explainLimit('min')
    }
    setArtistSplit(value)
    setLabelSplit(100 - value)
  }

  const handleChangeCommitted = () => {
    trackCustomEvent({
      category: "input",
      action: "slide",
      label: "split"
    })
  }

  // helpers

  const explainLimit = limit => {
    const maxExplanation = 'Hmmm... Are you Adele?'
    const minExplanation = "Don't agree to less than 5% please"
    if(limit === 'max') setExplanation(maxExplanation)
    if(limit === 'min') setExplanation(minExplanation)
    setOpen(true)
    setTimeout(() => setOpen(false), 3000)
  }

  // material ui styles

  const useStyles = makeStyles({
    rail: {
      color: '#e7e7e7',
      opacity: 1,
      height: 4
    },
    track: {
      height: 4,
      color: '#4156E8'
    },
    thumb: {
      color: '#e7e7e7',
      width: 20,
      height: 20,
      marginTop: -8,
      boxShadow: '0 0 5px #e7e7e7',
      '&:focus, &:hover, &$active': {
        boxShadow: '0 0 5px #e7e7e7',
      },
    },
  })

  const useStylesTooltip = makeStyles({
    tooltip: {
      // minWidth: 200,
      width: 200,
      backgroundColor: 'white',
      color: 'black',
      padding: 10
    }
  })

  const classes = useStyles()

  const classesTooltip = useStylesTooltip()

  // render

  return (
    <div className="margin-top input-element" id="split">
      <label className="label-1">{dealType.value === 'distribution-deal' ? 'Revenue Share' : 'Split'}</label>
      <Tooltip title={explanation} open={open} classes={classesTooltip} placement={'top'}>
        <Slider
          // track={false}
          aria-labelledby="track-false-slider"
          getAriaValueText={valuetext}
          onChange={handleChange}
          onChangeCommitted={handleChangeCommitted}
          value={artistSplit}
          classes={classes}
          max={100}
          min={0}
        />
      </Tooltip>
      <div className="flex-row space-between" id="split-labels">
        <span className="label-2"><span className="bold">Artist:</span> {artistSplit}%</span>
        <span className="label-2"><span className="bold">Label:</span> {labelSplit}%</span>
      </div>
    </div>
  )
}

export default Split