import React, { useState } from 'react'
import { useGlobal } from 'reactn'
import link from '../../images/link.png'
import tweet from '../../images/tweet.png'
import { TwitterShareButton } from 'react-share'
import copy from 'copy-to-clipboard'
import Tooltip from '@material-ui/core/Tooltip'
import fbTrack from '../../fb-track'

import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

import './index.css'

const SharingIsCaring = ({ origin }) => {

  // global state

  const [global] = useGlobal()  

  // local state

  const [open, setOpen] = useState(false)

  // helpers

  const makeQueryString = () => {
    const deal = `dealType=${global.dealType.value}&`
    const royalty = `artist-royalty=${global["artist-royalty"]}&`
    const fee = `distribution-fee=${global["distribution-fee"]}&`
    const advance = `advance=${global.advance}&`
    const recording = `recording-costs=${global["recording-costs"]}&`
    const marketing = `marketing-costs=${global["marketing-costs"]}&`
    const streams = `streams=${global.streams}&`
    const rate = `rate=${global.rate}`
    return origin+'/?'+deal+royalty+fee+advance+recording+marketing+streams+rate
  }

  const showConfirmation = () => {
    setOpen(true)
    setTimeout(() => setOpen(false), 1500)
  }

  const handleCopy = copyText => {
    copy(copyText)
    showConfirmation()
    trackCustomEvent({ // google analytics
      category: "button",
      action: "click",
      label: "copy-link"
    })
    fbTrack('trackCustom', 'CopyLink') // facebook pixel
  }

  const handleTweetClick = () => {
    trackCustomEvent({
      category: "button",
      action: "click",
      label: "tweet"
    })
    fbTrack('trackCustom', 'ShareTwitter')
  }

  return(
    <div id="sharing-is-caring" className="flex-center self-centered column text-center">
      <div id="sic-content">
        <p>You can copy a link to share out your label deal or share directly to twitter.</p>
        <div className="flex-row space-between full-width" id="share-buttons">
          <Tooltip title="Copied!" open={open}>
            <button className="flex-center button" onClick={() => handleCopy(makeQueryString())}><img src={link} id="link-icon" />Copy Link</button>
          </Tooltip>
          <TwitterShareButton id="tweet-button" onClick={handleTweetClick} title={'I modeled a record deal using the @CreateOS Record Deal Simulator, check it out:'} hashtags={['dealsim', 'KnowYourself', 'KnowYourWorth' ]} url={makeQueryString()}>
            <button className="flex-center button"><img src={tweet} id="tweet-icon" />Tweet</button>
          </TwitterShareButton>
        </div>
      </div>
    </div>
  )
}

export default SharingIsCaring