import React from 'react'
import About from '../about'
import RequestResources from '../request-resources'

import './index.css'


const BottomOfPage = () => {
  return(
    <div id="bottom-of-page" className="self-centered flex-row space-between">
      <About />
      <div id="request-resources" className="width-45">
       <h4>Know yourself, know your worth.</h4>
        <p>Click&nbsp;<a href="https://createsafe.xyz/" target="_blank" rel="noreferrer" style={{ backgroundColor: 'black' }} className={`white-text bold`}>here</a>&nbsp;for early access to the Create/OS platform.</p>
        <RequestResources bottom />
      </div>
    </div>
  )
}

export default BottomOfPage