import React from 'react'
import { useGlobal } from 'reactn'

import RevenueHeading from './revenue-heading'
import StreamSlider from './stream-slider'
import LabelDisplayBar from './label-display-bar'
import BreakEven from './break-even'
import BottomSummary from './bottom-summary'
import ArtistDisplayBar from './artist-display-bar'
import RecoupedBar from './recouped-bar'
import RoyaltyRecoupBar from './royalty-recoup-bar'

const Revenue = () => {

  // global state

  const [recoupableAmount] = useGlobal('recoupable-amount')
  const [dealType] = useGlobal('dealType')

  // render

  return (
    <div className="column flex-one bg-1" id="revenue">
      <div className="border-bottom black-border" id="revenue-content">
        <RevenueHeading />
        <div className="flex-row margin-bottom space-between full-width" id="slider-label">
          <label className="label-1 margin-bottom padding-bottom">Streams</label>
        </div>
        <StreamSlider />
      </div>
      {/* <MobileSummary /> */}
      { dealType.value !== 'royalty' ? <RecoupedBar /> : null }
      { dealType.value === 'royalty' ? <RoyaltyRecoupBar /> : null }
      <ArtistDisplayBar />
      <LabelDisplayBar />
      <BottomSummary />
      {/* <BreakEven /> */}
    </div>
  )
}

export default Revenue