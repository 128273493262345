import React, { useState } from 'react'
import { useGlobal } from 'reactn'

import Slider from '@material-ui/core/Slider'
import { makeStyles } from '@material-ui/core/styles'
import Info from '../info'

import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

const RateSlider = () => {

  // global state

  const [rate, setRate] = useGlobal('rate')

  // local state

  const [sliderValue, setSliderValue] = useState(rate * 1000)

  // accessibility
  
  function valuetext(value) {
    return `${value}°C`;
  }

  // handlers

  const handleChange = (e, v) => {
    let value = v
    if(v <= 1) value = 1
    setSliderValue(value)
    setRate(transformSliderValueToRate(value))
  }

  const handleChangeCommitted = () => {
    trackCustomEvent({
      category: "input",
      action: "slide",
      label: "rate"
    })
  }

  // material ui styles

  const useStyles = makeStyles({
    rail: {
      color: '#e7e7e7',
      opacity: 1,
      height: 4
    },
    track: {
      height: 4,
      color: '#4156E8'
    },
    thumb: {
      color: '#e7e7e7',
      width: 20,
      height: 20,
      marginTop: -8,
      boxShadow: '0 0 5px #e7e7e7',
      '&:focus, &:hover, &$active': {
        boxShadow: '0 0 5px #e7e7e7',
      },
    },
  })

  const classes = useStyles()

  // helpers

  const getLabel = () => {
    const parsed = Number.parseFloat(transformSliderValueToRate(sliderValue))
    if(parsed !== .001 && parsed !== .01) return parsed.toFixed(4)
    return parsed
  }

  const transformSliderValueToRate = value => value / 1000

  // render

  return (
    <div className="input-element" id="rate-slider">
      <div className="flex-row margin-bottom space-between">
        <label className="label-1">Rate</label>
        <Info title={'rate'} />
      </div>
        <Slider
          // track={false}
          aria-labelledby="track-false-slider"
          getAriaValueText={valuetext}
          onChange={handleChange}
          onChangeCommitted={handleChangeCommitted}
          value={sliderValue}
          classes={classes}
          min={1}
          max={10}
          step={.1}
        />
      <div className="flex-row space-between padding-top align-center" id="rate-label">
        <span className="label-2 bold">${getLabel()}</span>
        <span className="label-1 bold">PER STREAM</span>
      </div>
    </div>
  )
}

export default RateSlider