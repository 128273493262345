import React, { useState } from "react"
import { setGlobal, useEffect, useGlobal } from 'reactn'

import Layout from '../components/layout'
import Header from '../components/header'
import SEO from '../components/seo'
import PageHeading from '../components/page-heading'
import PageDescription from '../components/page-description'
import Prompt from '../components/prompt'
import DealSimulator from '../components/deal-simulator'
import SharingIsCaring from '../components/sharing-is-caring'
import BottomOfPage from '../components/bottom-of-page'
import Blob from '../components/blob'
import Banner from '../components/banner'

import './index.css'
import Footer from "../components/footer"

const IndexPage = ({ location }) => {

  const [dealType, setDealType] = useGlobal("dealType")
  const [artistRoyalty, setArtistRoyalty] = useGlobal("artist-royalty")
  const [distributionFee, setDistributionFee] = useGlobal("distribution-fee")
  const [advance, setAdvance] = useGlobal('advance')
  const [recordingCosts, setRecordingCosts] = useGlobal('recording-costs')
  const [marketingCosts, setMarketingCosts] = useGlobal('marketing-costs')
  const [streams, setStreams] = useGlobal('streams')
  const [maxStreams, setMaxStreams] = useGlobal('max-streams')

  const [hasSubmitted] = useGlobal('hasSubmitted')
  const [rate, setRate] = useGlobal('rate')

  const [initialized, setInitialized] = useState(false)
  const [showResources, setShowResources] = useState(true)
  const [showFullPage, setshowFullPage] = useState(true)

  useEffect(() => {

    if(typeof window === 'undefined') return null
    
    const { location } = window

    const queryString = require('query-string')

    const parsed = queryString.parse(location.search);
    const queryObject = JSON.parse(JSON.stringify(parsed)) // create a js object so we can check using hasOwnProperty for keys

    const hasDealType = queryObject.hasOwnProperty('dealType')
    const hasArtistRoyalty = queryObject.hasOwnProperty('artist-royalty')
    const hasDistributionFee = queryObject.hasOwnProperty('distribution-fee')
    const hasAdvance = queryObject.hasOwnProperty('advance')
    const hasRecordingCosts = queryObject.hasOwnProperty('recording-costs')
    const hasMarketingCosts = queryObject.hasOwnProperty('marketing-costs')
    const hasStreams = queryObject.hasOwnProperty('streams')
    const inApp = queryObject.hasOwnProperty('in-app')

    if(inApp) setshowFullPage(false)

    // make sure all parameters are in query

    if(hasDealType && hasArtistRoyalty && hasDistributionFee && hasAdvance && hasRecordingCosts && hasMarketingCosts && hasStreams) {
      
      let defaultArtistRoyalty = 20

      // if it's a net split, set it, otherwise default to royalty

      if(queryObject.dealType === 'net-profit-split') {
        setDealType({
          label: 'Net Profit Split',
          value: 'net-profit-split',
        })

        // only net profit splits have distribution fees, so isolate it

        const fee = parseInt(queryObject["distribution-fee"], 10)
        if(fee <= 25 && fee >=0 ) setDistributionFee(fee)
        defaultArtistRoyalty = 50
      } 
      else if(queryObject.dealType === 'distribution-deal') {
        setDealType({
          value: 'distribution-deal', 
          label: 'Distribution Deal'                    
        })
        defaultArtistRoyalty = 70
      } 
      else {
        setDealType({
          label: 'Royalty Deal',
          value: 'royalty',
        })
      }

      // tranform values into integers and perform range checks on values

      const royalty = parseInt(queryObject["artist-royalty"], 10)
      if(royalty >=5 && royalty <= 95) setArtistRoyalty(queryObject["artist-royalty"])
        else setArtistRoyalty(defaultArtistRoyalty)

      const advanceAllowed = v => {
        return (v >= 0 && v <= 60000000)
      }
      const recordingCostsAllowed = v => {
        return (v >= 0 && v <= 30000000)
      }
      const marketingCostsAllowed = v => {
        return (v >= 0 && v <= 60000000)
      }

      const adv = parseInt(queryObject.advance, 10)
      if(advanceAllowed(adv)) setAdvance(adv)
      const recCosts = parseInt(queryObject["recording-costs"], 10)
      if(recordingCostsAllowed(recCosts)) setRecordingCosts(recCosts)
      const markCosts = parseInt(queryObject["marketing-costs"], 10)
      if(marketingCostsAllowed(markCosts)) setMarketingCosts(markCosts)

      const localStreams = parseInt(queryObject.streams, 10)

      if(localStreams > 0 && localStreams <= 100000000000){
        setMaxStreams(localStreams * 2)
        setStreams(localStreams)
      }
      if(localStreams === 0) {
        setMaxStreams(2000000000)
        setStreams(0)
      }
      const localRate = parseFloat(queryObject.rate, 10)
      if(localRate >= .001 && localRate <= .01) setRate(localRate)
      else setRate(.005)
    }
    else {
      setGlobal({
        "current-tier": 1,
        "min-streams": 1000000,
        streams: 0,
        "max-streams": 2000000000,
        income: 0,
        "max-income": 0,
        "artist-royalty": 20,
        "label-royalty": 80,
        "artist-profit": 0,
        "label-profit": 0,
        "distribution-fee": 0,
        advance: 400000,
        "recording-costs": 200000,
        "marketing-costs": 400000,
        "recoupable-amount": 1000000,
        rate: .0039,
        dealType: {
          label: 'Royalty Deal',
          value: 'royalty',
        },
      })
    }
    setInitialized(true)
  },[])

  useEffect(() => {
    if(hasSubmitted === true) setTimeout(() => handleSlideUp(), 2000)
  }, [hasSubmitted])

  const handleSlideUp = () => {
    setShowResources(false)
  }

  // initialize()

  if(!initialized) return null

  return(
    <Layout>
      <div id="index-page" className="flex column">
        <SEO title="Home" />
        { showFullPage && 
          <>
            <Banner />
            <Header />
            <Blob />
            <PageHeading />
            <PageDescription />
            <Prompt />
          </>
        }
        <div className="flex column align-center">
          <div id="main" className="flex-row">
            <DealSimulator />
          </div>
          <SharingIsCaring origin={location.origin} />
          { showFullPage && <BottomOfPage /> }
        </div>
      </div>
      { showFullPage && <Footer /> }
    </Layout>
  )
}

export default IndexPage