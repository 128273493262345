import React, { useState, useEffect } from 'react'
import { useGlobal } from 'reactn'

import Slider from '@material-ui/core/Slider'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import NumberFormat from 'react-number-format'

import EditStreams from './edit-streams'
import MakeMoney from '../../../make-money'

import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

// import { tiers } from './tiers'

import './stream-slider.css'

const StreamSlider = () => {

  // global state

  const [minStreams, setMinStreams] = useGlobal('min-streams')
  const [streams, setStreams] = useGlobal('streams')
  const [maxStreams, setMaxStreams] = useGlobal('max-streams')
  const [income, setIncome] = useGlobal('income')
  const [maxIncome, setMaxIncome] = useGlobal('max-income')
  const [recoupableAmount] = useGlobal('recoupable-amount')
  const [rate, setRate] = useGlobal('rate')

  // local state

  const [totalProfit, setTotalProfit] = useState()
  const [absoluteMinStreams, setAbsoluteMinStreams] = useState(.15)
  const [absoluteMaxStreams, setAbsoluteMaxStreams] = useState(300000)
  const [streamsLabel, setStreamsLabel] = useState('')

  // effects

  useEffect(() => {
    let iAintNoProphet = income - recoupableAmount
    if(iAintNoProphet <= 0) iAintNoProphet = 0
    setTotalProfit(iAintNoProphet)
  }, [income, recoupableAmount])

  useEffect(() => {
    setMaxIncome(maxStreams * rate)
  }, [maxStreams, rate])

  useEffect(() => {
    setStreamsLabel(getLabel(streams))
  }, [streams, maxStreams])

  // material ui styles

  const fontLiteral = `-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`

  const getRadius = () => {
    if((income / maxIncome) > (99.5/100)) return 20
    else return 0
  }

  const useStyles = makeStyles({
    rail: {
      color: '#e7e7e7',
      opacity: 1,
      height: 4
    },
    track: {
      height: 4,
      color: '#4156E8',
      borderTopRightRadius: getRadius(),
      borderBottomRightRadius: getRadius()
    },
    thumb: {
      color: '#e7e7e7',
      width: 20,
      height: 20,
      marginTop: -8,
      boxShadow: '0 0 5px #e7e7e7',
      '&:focus, &:hover, &$active': {
        boxShadow: '0 0 5px #e7e7e7',
      },
    },
    valueLabel: {
      color: 'transparent',
      fontSize: 20,
      fontWeight: 300,
      left: -7,
      fontFamily: fontLiteral,
      letterSpacing: 1.5
    },
    markLabel: {
      color: '#8f8f8f'
    },
  })

  const classes = useStyles()

  // effects

  useEffect(() => {
    let val = streams * rate
    setIncome(val)
  }, [recoupableAmount, streams, rate])

  const handleChange = (e, v) => {
    setStreams(v) // e is event v is value
  }

  const handleChangeCommitted = () => {
    trackCustomEvent({
      category: "input",
      action: "slide",
      label: "streams"
    })
  }

  const getLabel = value => {
    let finalLabel
    // const roundedStreams = Number(Math.round((recoupableAmount / 1000000) + "e" + 1) + "e-" + 1)
    const roundedStreams = Math.round((value + Number.EPSILON) * 100) / 100
    if(roundedStreams >= 1000000000) {
      const streamsInBillions = (roundedStreams / 1000000000)
      const roundedBillions = Number(Math.round((streamsInBillions) + "e" + 1) + "e-" + 1)
      // const roundedBillions = Math.round((streamsInBillions + Number.EPSILON) * 100) / 100
      const label = 'B'
      finalLabel = roundedBillions + label
      return finalLabel
    }
    if(roundedStreams >= 1000000) {
      const streamsInMillions = (roundedStreams / 1000000)
      const roundedMillions = Number(Math.round((streamsInMillions) + "e" + 1) + "e-" + 1)
      finalLabel = roundedMillions + 'M'
      return finalLabel
    }
    if(roundedStreams > 0) {
      const streamsInThousands = roundedStreams / 1000
      const roundedThousands = Number(Math.round((streamsInThousands) + "e" + 1) + "e-" + 1)
      const label = 'K'
      finalLabel = roundedThousands + label
      return finalLabel
    }
    return ''
  }

  const getStep = () => maxStreams / 100

  // marks for slider 

  const marks = [
    {
      value: 0,
      label: 0
    },
    {
      value: (maxStreams / 2),
      label: getLabel(maxStreams / 2)
    },
    {
      value: Math.round(maxStreams),
      label: getLabel(maxStreams)
    },
  ]

  // render

  return (
    <div id="stream-slider">
      {/* <span id="min-value">0</span>
      <span id="max-value">{getLabel(maxStreams)}</span> */}
      <Slider
        getAriaValueText={getLabel}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
        value={streams}
        max={maxStreams}
        min={0}
        valueLabelDisplay="on"
        valueLabelFormat={getLabel}
        // valueLabel={streamsLabel}
        classes={classes}
        // ThumbComponent={EditableLabel}
        // ValueLabelComponent={EditableLabel}
        step={getStep()}
        marks={marks}
      />
      <EditStreams />
      <div id="slider-summary" className="flex-row space-between">
        <div className="column" id="ssc-1">
          <label className="label-1 pad-vert-half">Gross Revenue: </label>
          <NumberFormat value={income} decimalScale={0} displayType={'text'} thousandSeparator={true} prefix={'$'} />
        </div>
        <div className="column">
          <label className="label-1 pad-vert-half">Costs: </label>
          <NumberFormat value={recoupableAmount} decimalScale={0} displayType={'text'} thousandSeparator={true} prefix={'$'} className="red-text" />
        </div>
      </div>
    </div>
  )
}

export default StreamSlider