import React, { useState, useEffect } from 'react'
import { useGlobal } from 'reactn'
import NumberFormat from 'react-number-format'
import { getSuffix } from './get-suffix'
import Info from '../info'

import './index.css'
import { computeLabelBalance } from './math'

const BottomSummary = () => {

  // global state

  const [global] = useGlobal()
  const [artistProfit] = useGlobal('artist-profit')
  const [labelProfit] = useGlobal('label-profit')
  const [dealType] = useGlobal('dealType')
  const [distributionFee] = useGlobal('distribution-fee')
  const [income] = useGlobal('income')

  // local state

  const [suffix, setSuffix] = useState()

  // effects

  useEffect(() => {
    let distroFee = 0
    if(distributionFee >= 0 && distributionFee <= 25) distroFee = distributionFee
    const value = labelProfit + ((distroFee/100) * income)
    const suff = getSuffix(value)
    setSuffix(suff)
  }, [labelProfit, distributionFee,  income])

  // helpers

  const getRounded = value => Number(Math.round((value) + "e" + 2) + "e-" + 2)

  const getValue = (v, party) => {
    let value = v
    // if(dealType.value === 'royalty' && party === 'label' || dealType.value === 'distribution-deal' && party === 'label') value = labelProfit
    // if(dealType.value === 'net-profit-split' && party === 'label') value = v + ((distributionFee/100) * income)
    if(value <= 0) return 0
    if(value > .5 && value < 2) return 1
    if(v < 1000) return getRounded(v).toFixed(0)
    if(value < 1000000) return getRounded(value / 1000)
    if(value < 1000000000) return getRounded(value / 1000000)
    return getRounded(value / 1000000000)
  }

  // render

  return (
    <div id="bottom-summary" className="flex-one" style={ dealType.value === 'royalty' ? null : { padding: '45.5px 40px'} }>
      <div className="column">
        <label className="label-3">Artist Total Profit: </label>
        <NumberFormat value={getValue(artistProfit, 'artist')} displayType={'text'} thousandSeparator={true} prefix={'$'} suffix={getSuffix(artistProfit)} className="total-number" />
      </div>
      <div className="column">
        <div className="flex-row flex-center">
          <label className="label-3" style={{ marginRight: 5 }}>Label Total Profit: </label>
          {dealType.value === 'net-profit-split' ? <Info title={'label-total-profit'} /> : null}
        </div>
        <NumberFormat value={getValue(computeLabelBalance(global), 'label')} displayType={'text'} thousandSeparator={true} prefix={'$'} suffix={suffix} className="total-number" />
      </div>
    </div>
  )
}

export default BottomSummary