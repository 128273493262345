import React, { useState } from 'react'
import { useGlobal } from 'reactn'

import NumberFormat from 'react-number-format'
import Slider from '@material-ui/core/Slider'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'

import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

import Info from '../../info'

const Fee = () => {

  // global state

  const [distributionFee, setDistributionFee] = useGlobal('distribution-fee')
  const [dealType] = useGlobal('dealType')
  const [income] = useGlobal('income')

  // local state

  const [open, setOpen] = useState(false)

  // accessibility
  
  function valuetext(value) {
    return `${value}°C`;
  }

  // handlers

  const handleChange = (e, v) => {
    let fee = v
    if(v >= 25) {
      fee = 25
      if(distributionFee !== 25) explainLimit()
    }
    setDistributionFee(fee)
  }

  const handleChangeCommitted = () => {
    trackCustomEvent({
      category: "input",
      action: "slide",
      label: "distribution-fee"
    })
  }

  // helpers

  const explainLimit = () => {
    setOpen(true)
    setTimeout(() => setOpen(false), 3000)
  }

  const getDistributionAmount = () => '$' + ((distributionFee / 100) * income).toString()

  // material ui styles

  const useStyles = makeStyles({
    rail: {
      color: '#e7e7e7',
      opacity: 1,
      height: 4
    },
    track: {
      height: 4,
      color: '#4156E8'
    },
    thumb: {
      color: '#e7e7e7',
      width: 20,
      height: 20,
      marginTop: -8,
      boxShadow: '0 0 5px #e7e7e7',
      '&:focus, &:hover, &$active': {
        boxShadow: '0 0 5px #e7e7e7',
      },
    },
  })

  const useStylesTooltip = makeStyles({
    tooltip: {
      // minWidth: 200,
      width: 200,
      backgroundColor: 'white',
      color: 'black',
      padding: 10
    }
  })

  const classes = useStyles()

  const classesTooltip = useStylesTooltip()

  if(dealType.value !== 'net-profit-split') return null

  // render

  return (
    <div className="input-element margin-top">
      <div className="margin-bottom flex-row align-center space-between">
        <label className="label-1">Distribution Fee</label>
        <Info title={'distribution-fee'} />
      </div>
      <Tooltip title="It would be extremely unusual to have a fee higher than 25%" open={open} classes={classesTooltip} placement={'top'}>
        <Slider
          // track={false}
          aria-labelledby="track-false-slider"
          getAriaValueText={valuetext}
          defaultValue={15}
          onChange={handleChange}
          onChangeCommitted={handleChangeCommitted}
          value={distributionFee}
          classes={classes}
        />
      </Tooltip>
      <div className="flex-row space-between">
        <span className="label-2">{distributionFee}% </span>
        <NumberFormat value={getDistributionAmount()} displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'$'} className="label-2" />
      </div>
    </div>
  )
}

export default Fee