import React from 'react'
import { useGlobal } from 'reactn'
import TypeSplitFee from './type-split-fee'
import Debt from './debt'

const DealTerms = () => {

  // render

  return (
    <div className="column border-right padding bg-1" id="deal-terms">
      <TypeSplitFee />
      <Debt />
    </div>
  )
}

export default DealTerms