import React from 'react'

import { OutboundLink } from 'gatsby-plugin-google-analytics'

import './index.css'

const PageDescription = () => {

  const description = "Many artists sign record deals they don’t fully understand. We’ve created a record deal simulator to help artists, managers, and labels model deal options and forecast profits. It’s a rough guide to better understand the finances of how record deals work."
  const href = "https://appliedscience.substack.com/p/applied-science-4-record-deal-simulator"
  const label = "Learn More"

  // main render

  return(
    <p id="simulator-description">
      {description}
      <br />
      <br />
      <OutboundLink 
        href={href}
        target="_new"
      >
        <button id="learn-more-button" className="flex-center button white-button">{label}</button>
      </OutboundLink>
      <OutboundLink id="aco" href="https://pubsim.createos.app/" target="_new">Also check out the Publishing Simulator!</OutboundLink>
    </p>
  )
}

export default PageDescription