import React, { useState, useEffect } from 'react'
import NumberFormat from 'react-number-format'
import { useGlobal } from 'reactn'

import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

import Info from '../info'

import './index.css'

const Debt = () => {

  // global state

  const [advance, setAdvance] = useGlobal('advance')
  const [recordingCosts, setRecordingCosts] = useGlobal('recording-costs')
  const [marketingCosts, setMarketingCosts] = useGlobal('marketing-costs')
  const [recoupableAmount, setRecoupableAmount] = useGlobal('recoupable-amount')

  // local state

  const [advancedEdited, setAdvanceEdited] = useState(false)
  const [recordingCostsEdited, setRecordingCostsEdited] = useState(false)
  const [marketingCostsEdited, setMarketingCostsEdited] = useState(false)

  // effects

  useEffect(() => {
    const recoupable = parseInt(advance) + parseInt(recordingCosts) + parseInt(marketingCosts)
    setRecoupableAmount(recoupable)
  }, [advance, recordingCosts, marketingCosts])

  // helpers

  const getValue = v => v === '' ? 0 : v

  const getMillions = () => Number(Math.round((recoupableAmount / 1000000) + "e" + 2) + "e-" + 2)

  const advanceAllowed = v => {
    return (v >= 0 && v <= 60000000)
  }
  const recordingCostsAllowed = v => {
    return (v >= 0 && v <= 30000000)
  }
  const marketingCostsAllowed = v => {
    return (v >= 0 && v <= 60000000)
  }

  // change handlers

  const handleAdvanceChange = value => {
    setAdvance(value)
    setAdvanceEdited(true)
    trackCustomEvent({
      category: "input",
      action: "edit",
      label: "advance"
    })
  }

  const handleRecordingCostsChange = value => {
    setRecordingCosts(value)
    setRecordingCostsEdited(true)
    trackCustomEvent({
      category: "input",
      action: "edit",
      label: "recording-costs"
    })
  }

  const handleMarketingCostsChange = value => {
    setMarketingCosts(value)
    setMarketingCostsEdited(true)
    trackCustomEvent({
      category: "input",
      action: "edit",
      label: "marketing-costs"
    })
  }

  // render

  return (
    <>
      <div className="column flex-one" id="debt">
        <div className="margin-bottom">
          <div className="mg-bt-hf flex-row align-center space-between">
            <label className="label-1">Artist Advance</label>
            <Info title={'advance'} />
          </div>
          <NumberFormat onBlur={advance === '' ? () => setAdvance(0) : null} value={advance} decimalScale={0} thousandSeparator={true} prefix={'$'} onValueChange={values => handleAdvanceChange(values.value)} isAllowed={values => advanceAllowed(values.value)} className="debt-input mg-bt-24" onMouseUp={e => e.target.select()} />
        </div>
        <div className="margin-bottom">
          <div className="mg-bt-hf flex-row align-center space-between">
            <label className="label-1">Recording Costs</label>
            <Info title={'recording-costs'} />
          </div>          
          <NumberFormat onBlur={recordingCosts === '' ? () => setRecordingCosts(0) : null} value={recordingCosts} decimalScale={0} thousandSeparator={true} prefix={'$'} onValueChange={values => handleRecordingCostsChange(values.value)} isAllowed={values => recordingCostsAllowed(values.value)} className="debt-input mg-bt-24" onMouseUp={e => e.target.select()} />
        </div>
        <div>
          <div className="mg-bt-hf flex-row align-center space-between">
            <label className="label-1">Marketing Costs</label>
            <Info title={'marketing-costs'} />
          </div> 
          <NumberFormat onBlur={marketingCosts === '' ? () => setMarketingCosts(0) : null} value={marketingCosts} decimalScale={0} thousandSeparator={true} prefix={'$'} onValueChange={values => handleMarketingCostsChange(values.value)} isAllowed={values => marketingCostsAllowed(values.value)} className="debt-input" onMouseUp={e => e.target.select()} />
        </div>
      </div>
      <div id="total-costs">
        <label className="label-3">Total Costs: </label>
        <NumberFormat value={getMillions()} displayType={'text'} thousandSeparator={true} prefix={'$'} suffix=" M" className="total-number" />
        <p>This amount must be recouped by artist</p>
      </div>
    </>
  )
}

export default Debt