import React from 'react'

import Type from './inputs/type'
import Split from './inputs/split'
import Fee from './inputs/fee'

const TypeSplitFee = () => {

  // render

  return (
    <div className="column border-bottom" id="type-split-fee">
      <Type />
      <Split />
      <Fee />
    </div>
  )
}

export default TypeSplitFee