import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import closeX from './close-x.png'

import './index.css'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: 'rgb(31, 31, 31)',
    boxShadow: theme.shadows[5],
    padding: '1rrem 0',
    outline: 0,
    // border: '1px solid #7a7a7a',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
}));

export const ApplyModal = ({ children, open, handleClose }) => {
  const classes = useStyles();

  return (
    <div id="apply-modal" className="absolute">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper} id="apply-modal-content">
            <img id="close-x" src={closeX} onClick={handleClose} className="phone-only" />
            {children}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}