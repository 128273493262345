import React, { useState, useEffect } from 'react'
import { useGlobal } from 'reactn'
import NumberFormat from 'react-number-format'
import { makeStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'

import '../index.css'

// component 

const RecoupedBar = () => {

  // global state

  const [recoupableAmount] = useGlobal('recoupable-amount')
  const [income] = useGlobal('income')
  const [dealType] = useGlobal('dealType')
  const [distributionFee] = useGlobal('distribution-fee')

  // local state

  const [recoupedPercentage, setRecoupedPercentage] = useState()

  // effects

  useEffect(() => {
    const distributionFeeAsDecimal = distributionFee / 100
    const incomeLessDistributionFee = income * (1 - distributionFeeAsDecimal)
    const recoupedPercent = (incomeLessDistributionFee / recoupableAmount) * 100
    const maxed = recoupedPercent >= 100
    const minned = recoupedPercent <= 0
    if(maxed) setRecoupedPercentage(100)
    if(minned) setRecoupedPercentage(0)
    if(!maxed && !minned) setRecoupedPercentage(recoupedPercent)
  }, [income, recoupableAmount, dealType, distributionFee])

  // helpers

  // material ui

  const useStylesBase = makeStyles({
    root: {
      height: 40,
      borderRadius: 20,
      borderWidth: 1,
      borderColor: '#7a7a7a',
      borderStyle: 'solid',
      marginBottom: 12.5,
    },
    colorPrimary: {
      backgroundColor: 'transparent',
    },
    bar: {
      backgroundColor: '#F8765F',
      opacity: .5,
      transition: 'none',
      zIndex: 1
    },
  })

  const useStyles = makeStyles({
    root: {
      height: 40,
      borderRadius: 20,
      borderWidth: 1,
      borderColor: '#7a7a7a',
      borderStyle: 'solid',
      marginBottom: 12.5,
    },
    colorPrimary: {
      backgroundColor: 'transparent',
    },
    bar: {
      // borderRadius: 5,
      backgroundColor: '#F8765F',
      transition: 'none'
    },
  })

  const classesBase = useStylesBase()
  const classes = useStyles()

  // render functions

  const renderLabelsRow = () => (
    <div className="labels-row">
      <div className="column flex-one">
        <label className="label-1">Artist Balance Recouped: &nbsp;</label>
        <NumberFormat value={recoupedPercentage} displayType={'text'} decimalScale={0} thousandSeparator={true} suffix={' %'} />
      </div>
    </div>
  )

  const renderProgressBar = () => {
    return(
      <div style={{ position: 'relative', marginBottom: 52.5 }} id="recouped-bar">
        <div style={{ width: '100%', position: 'absolute' }}>
          <LinearProgress
            value={100}
            variant="determinate"
            classes={classesBase}
          />
        </div>
        <div style={{ width: '100%', position: 'absolute' }}>
          <LinearProgress
            value={recoupedPercentage}
            variant="determinate"
            classes={classes}
          />
        </div>
      </div>
    )
  }

  if(dealType.value === 'royalty') return null

  // render 

  return(
    <div className="padding-40 border-bottom black-border" id="recouped-display">
      {renderProgressBar()}
      {renderLabelsRow()}
    </div>
  )
}

export default RecoupedBar