import React, { useState } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import infoIcon from '../../../../images/info.png'
import { makeStyles } from '@material-ui/core/styles'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import { OutboundLink, trackCustomEvent } from 'gatsby-plugin-google-analytics'

import './index.css'

const tips = {
  "net-profit-split": {
    heading: "Net Profit Split Deal",
    body: ["A scenario where the net profit will be split—usually 50-50—between the artist and the record label, after ALL expenses connected with the artist's records have been repaid and deducted by the label from record sales income."],
  },
  royalty: {
    heading: "Royalty Deal",
    body: ["Royalty deals are the most common form of record deal. They tend to heavily favor the label in terms of potential profit, but have the upside of allowing or incentivizing labels to invest or spend more money knowing their reward could be greater.", "Music royalties are payments that go to recording artists, songwriters, composers, publishers, and other copyright holders for the sale and reproduction of their intellectual property.", "Contracts define royalty agreements between the creator and the distributor."],
  },
  "distribution-deal": {
    heading: "Distribution Deal",
    body: ["Distribution deals historically have been deals that allowed artists to maintain ownership of their masters while receiving funds and services for the promotion of their music.", "Repayment of costs and profits can be governed either by the net profit split or royalty models.", "In the Record Deal Simulator, we are modeling distribution deals using the net profit split structure."],
  },
  "distribution-fee": {
    heading: "Distribution Fee",
    body: ["A fee charged by a record label for services related to distribution.", "This fee is assessed BEFORE artist and label shares of earnings are calculated."],
  },
  advance: {
    heading: "Advance",
    body: ["An advance is almost always defined as a pre-payment of royalties, whether the advance is paid by a record company to an artist, a publisher to a writer or a merchandiser to an artist.", "An advance is also sometimes called a “minimum guarantee.”", "Advances in the music industry do not earn interest and are not loans. The advance is only “recoupable”—meaning that the advance is applied against earned royalties."]
  },
  "recording-costs": {
    heading: "Recording Costs",
    body: ["The amount of money it takes to record a song or album from start to finish.", "Costs include but are not limited to: studio rental, recording engineer, mixing, orchestration, musicians, mastering, licensing, graphic design, duplication, producer.", "Needs may vary depending on the type of record the artist wants to create."]
  },
  "marketing-costs": {
    heading: "Marketing Costs",
    body: ["Costs to promote an album or song. In a traditional recording deal, many, but not all, marketing costs are recoupable.", "In a net profit deal, marketing, promotion, tour support, recording costs, and corporate costs are all recoupable.", "Recoupable costs are “debited” to an artist's royalty account, and revenues are “credited” to the artist's royalty account."]
  },
  // "streams-revenue": {
  //   heading: "Streams & Revenue",
  //   body: ["Revenue is being calculated by estimating a royalty rate of $0.005 per stream, which is a rough average across major streaming platforms (Apple Music & Spotify).", "Please note that streaming royalty rates vary across services.", <span>Further reading <OutboundLink href="https://thetrichordist.com/category/royalty-rates-2/" target="_blank" rel="noreferrer">here</OutboundLink></span>]
  // },
  "label-total-profit": {
    heading: "Label Total Profit",
    body: ["This figure is a combination of net profit and the revenue from the label's distribution fee, if applicable."]
  },
  rate: {
    heading: "Streaming Royalty Rates",
    body: ["A streaming royalty rate is the average rate paid to rights holders per play of a song.", "Streaming royalty rates vary widely across services.", <span>Find more information about streaming rates payed by different services <OutboundLink href="https://thetrichordist.com/category/royalty-rates-2/" target="_blank" rel="noreferrer">here</OutboundLink></span>, "Tweet @createos with your thoughts on the future of streaming royalty rates."]
  }
}

const Info = ({ title }) => {

  // local state

  const [open, setOpen] = useState(false)

  const { heading, body } = tips[title]

  // handlers

  const handleClick = () => {
    if(open === false) {
      trackCustomEvent({
        category: "button",
        action: "click",
        label: `info-${title}`
      })
    }
    setOpen(currentIsOpen => !currentIsOpen)
  }

  // material ui styles

  const useStyles = makeStyles({
    tooltip: {
      backgroundColor: 'white',
      color: 'black',
      padding: 35,
      margin: 0,
      top: 10
    },
    arrow: {
      color: 'white'
    }
  })

  const classes = useStyles()
  
  return(
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Tooltip 
        open={open}
        interactive
        arrow
        classes={classes}
        TransitionProps={{ timeout: {
          enter: 500,
          exit: 200
        } }}
        title={
          <div className="tooltip-content">
            <h2>{heading}</h2>
            {body.map(curr => <p>{curr}</p>)}
          </div>
        }>  
        <img src={infoIcon} className="info-icon" onClick={handleClick} />
      </Tooltip>
    </ClickAwayListener>
  )
}

export default Info