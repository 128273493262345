import React from 'react'

import './index.css'

const Prompt = () => {

  // render

  return (
    <div id="prompt" className="padding self-centered margin-bottom">
      <h4 className="mobile-only text-center">Basic deal info:</h4>
    </div>
  )
}

export default Prompt