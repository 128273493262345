import React, { useState, useEffect } from 'react'
import { useGlobal } from 'reactn'
import NumberFormat from 'react-number-format'
import { makeStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import { computeShare, computeProfit, computeUnrecouped } from './math'

import '../index.css'

// component 

const ArtistDisplayBar = () => {

  // global state

  const [global] = useGlobal()
  const [recoupableAmount] = useGlobal('recoupable-amount')
  const [artistRoyalty] = useGlobal('artist-royalty')
  const [income] = useGlobal('income')
  const [maxIncome] = useGlobal('max-income')
  const [dealType] = useGlobal('dealType')
  const [maxStreams] = useGlobal('max-streams')
  const [distributionFee, setDistributionFee] = useGlobal('distribution-fee')
  const [artistProfit, setArtistProfit] = useGlobal('artist-profit')
  const [rate, setRate] = useGlobal('rate')

  // local state

  const [royalty, setRoyalty] = useState()
  const [share, setShare] = useState()
  const [profit, setProfit] = useState()
  const [unrecoupedAmount, setUnrecoupedAmount] = useState()
  const [artistRecoupableWidth, setArtistRecoupableWidth] = useState()
  const [artistShareWidth, setArtistShareWidth] = useState()

  // effects

  useEffect(() => {
    setRoyalty(artistRoyalty)
  }, [artistRoyalty])

  useEffect(() => {
    setShare(computeShare(global, royalty, 'artist'))
    setProfit(computeProfit(global, royalty))
    setUnrecoupedAmount(computeUnrecouped(global, royalty))
    if(recoupableAmount >= 1) {
      if (recoupableAmount > maxIncome) setArtistRecoupableWidth(100)
      else setArtistRecoupableWidth((recoupableAmount / maxIncome) * 100)
    }
    else setArtistRecoupableWidth(.1)
    setArtistShareWidth((Math.max((share - recoupableAmount), 0) / maxIncome) * 100)
  }, [royalty, income, dealType, recoupableAmount, distributionFee, maxIncome])

  useEffect(() => {
    const getShareWidth = () => {
      if(dealType.value === 'royalty') {
        return (Math.max((share - recoupableAmount), 0) / maxIncome) * 100
      }
      if(dealType.value === 'net-profit-split' || dealType.value === 'distribution-deal') {
        const maxTotalIncome = maxStreams * rate
        const afterFee = maxTotalIncome * (1 - (distributionFee / 100))
        const maxArtistIncome = afterFee - recoupableAmount
        return (share / maxArtistIncome) * 100
      }
      
    }
    setArtistShareWidth(getShareWidth())
  }, [share, maxIncome, recoupableAmount, rate])

  useEffect(() => {
    setArtistProfit(profit)
  }, [profit, recoupableAmount])

  // helpers

  const getColor = () => {
    if(unrecoupedAmount === 0 && profit === 0) return '#2196f3'
    if(profit > 0) return '#52f9b8'
    if(profit === 0) return '#FF8974'
  }

  const getLabel = () => {
    if(unrecoupedAmount === 0 && profit === 0) return 'is Even'
    if(profit > 0) return 'Net Profit:'
    return 'Unrecouped:'
  }

  // const getShareColor = () => {
  //   if(dealType.value === 'net-profit-split' || dealType.value === 'distribution-deal') return '#2FB380'
  //   else {
  //     if(share > recoupableAmount) return '#2FB380'
  //     return '#F8765F'
  //   }
  // } 

  // material ui

  const useStylesBase = makeStyles({
    root: {
      height: 40,
      borderRadius: 20,
      borderWidth: 1,
      borderColor: '#7a7a7a',
      borderStyle: 'solid',
      marginBottom: 12.5,
    },
    colorPrimary: {
      backgroundColor: 'transparent',
    },
    bar: {
      backgroundColor: '#F8765F',
      opacity: share > recoupableAmount ? 1 : .5,
      transition: 'none',
      zIndex: share > recoupableAmount ? 1 : 0
    },
  })

  const useStyles = makeStyles({
    root: {
      height: 40,
      borderRadius: 20,
      borderWidth: 1,
      borderColor: '#7a7a7a',
      borderStyle: 'solid',
      marginBottom: 12.5,
    },
    colorPrimary: {
      backgroundColor: 'transparent',
    },
    bar: {
      // borderRadius: 5,
      backgroundColor: '#2FB380',
      transition: 'none'
    },
  })

  const classesBase = useStylesBase()
  const classes = useStyles()

  // render functions

  const renderLabelsRow = () => (
    <div className="labels-row">
      <div className="column flex-one lrc-1">
        <label className="label-1">artist royalty:&nbsp;</label>
        <NumberFormat value={profit > 0 ? profit : 0} displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'$'} />
      </div>
      <div className="column right-label">
        <label className="label-1">artist {getLabel()}&nbsp;</label>
        <NumberFormat style={ { color: getColor() } } decimalScale={0} value={profit > 0 ? profit : unrecoupedAmount} displayType={'text'} thousandSeparator={true} prefix={'$'} />
      </div>
    </div>
  )

  const renderProgressBar = () => {
    return(
      <div style={{ position: 'relative', marginBottom: 52.5 }} id="artist-display-bar">
        {/* <div style={{ width: '100%', position: 'absolute' }}>
          <LinearProgress
            value={dealType.value === 'royalty' ? artistRecoupableWidth : 0}
            variant="determinate"
            classes={classesBase}
          />
        </div> */}
        <div style={{ width: '100%', position: 'absolute' }}>
          <LinearProgress
            value={artistShareWidth}
            variant="determinate"
            classes={classes}
          />
        </div>
      </div>
    )
  }

  // render 

  return(
    <div className="padding-40 border-bottom black-border" id="artist-display">
      {renderProgressBar()}
      {renderLabelsRow()}
    </div>
  )
}

export default ArtistDisplayBar