import * as firebase from 'firebase'

const config = {
  apiKey: "AIzaSyCh2KvJ0CEhi6VGyR3uENxlYc966DHcXC4",
  authDomain: "cs-web-home.firebaseapp.com",
  databaseURL: "https://cs-web-home.firebaseio.com",
  projectId: "cs-web-home",
  storageBucket: "cs-web-home.appspot.com",
  messagingSenderId: "896800570000",
  appId: "1:896800570000:web:f776dcc5044f4dca08f615",
  measurementId: "G-TV7WF3J0SZ"
}

firebase.initializeApp(config)

export const signUpWithEmailAndPassword = async (email, password) => {
  const res = firebase.auth().createUserWithEmailAndPassword(email, password)
    .then(result => {
      console.log('user sucessfully created with password', result)
      return { status: 'success', action: 'sign up', method: 'password', user: result.user }
    })
    .catch(error => {
    // Handle Errors here.
    console.log('there was an error: ', error.code, error.message)
    return { status: 'error', code: error.code, message: error.message }
    // ...
  })
  return res
}

export const db = firebase.firestore()
